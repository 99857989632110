<template>
    <v-container class="w-container-edit-group v-overflow carica_cedolini_cud"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">



        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;">


                <div class="mainTitle" style="
                            background-color: white!important;
                            color: black;
                            font-weight: bold;
                            padding: 4px;
                            font-size: 10px;                
                            min-width:100vw;
                            border-color:#8ec8a3;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div class="flex-container">

                        <div class="flex-center">

                            <v-radio-group v-model="valueSelected" row style="margin-top:0px!important">

                                <v-radio label="Cedolini" value="cedolini"></v-radio>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <v-radio label="CUD" value="cud"></v-radio>

                            </v-radio-group>

                        </div>


                    </div>
                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="margin-left: 12px;">


                    <span class="files">
                        <input type="file" id="input-file-attach" name="logo" class="dropify" data-max-width="6610"
                            data-max-height="6200" />
                    </span>


                </v-col>

            </v-row>

            <v-row style="margin-top: 0px!important;">

                <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                    <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                    <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}</div>

                </v-col>

            </v-row>

            <v-row style="margin-top: 0px!important;">

                <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                    <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                    <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                </v-col>

            </v-row>






        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>


        <template>
            <modal name="popupWorkInProgress" :clickToClose="false" :width="350" :height="100">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important">

                        <div id="divMessages" style="font-size:12px;font-weight:bold;text-align:center;margin-top:31px">
                            Please Wait</div>

                    </v-col>

                </v-row>

            </modal>
        </template>




    </v-container>
</template>

<script>

import apidoxweb from "../utils/doxweb/apidoxweb";

import router from ".././router";


import {
    bus
} from "../main";


export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;


        var oggDrop = $('.dropify').dropify({
            messages: {
                default: 'Drag',
                replace: /* s */ '',
                remove: 'Rimuovi',
                error: 'File troppo grande'
            }
        });

        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
        $(".dropify-render").append("<img>");

        oggDrop.on('dropify.afterClear', function (event, element) {

            console.log(event, element);

            pointerVue.removeUploadFile = 1;

            pointerVue.nomeFilePresente = "";
            pointerVue.dimFilePresente = "";
            pointerVue.dimKbFilePresente = 0;

        });



        $('#input-file-attach').on('change', function () {

            pointerVue.removeUploadFile = 0;

            var v_file = $("#input-file-attach")[0].files[0];

            console.log("FILE: ", v_file);

            pointerVue.nomeFilePresente = v_file.name;

            var v_size = v_file.size;

            pointerVue.dimKbFilePresente = v_size;

            var str_size = "0 Kb";


            if (v_size > 0) {

                var v_division = v_size / 1000;

                str_size = v_division.toFixed(1) + " Kb";


            }



            pointerVue.dimFilePresente = str_size;



        });


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {



                case "btn_confirm":

                    this.validate();

                    break;


                case "btn_new_proc":

                    this.execNewProc();

                    break;



                default:
                    break;
            }
        });

        $(document).ready(function () {




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });

        });

        pointerVue.hideShowButtons(true);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({


        testoPulsante: "Upload Visura",
        titoloPopup: "Allega Visura Camerale",
        fileAccettati: ".pdf",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 5000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/Agila/Visura/",
        folderDoxToSave: "/Visure/",
        removeUploadFile: 0,
        attachRemoveVisura: false,

        valueSelected: "cedolini",

        IsReadonly: false,

        valoresino: [{ id: "S", name: "Si" }, { id: "N", name: "No" }],

        changePsw: false,

        titolo: "Load",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: { is_admin_company: "N", active: "Y" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],


        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,



        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        execNewProc: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.verifyFolderContent(
                0
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from verifyFolderContent", res);


                if (res.data.Result > 0) {


                    that.$modal.show('popupWorkInProgress');


                    setTimeout(() => {


                        $("#divMessages").html("Copy file in cache folder");

                        that.step1splitpdffordox();


                    }, 150);



                    //that.step1splitpdffordox();


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "No uploaded files to process",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;
                console.log(msg);

                that.$swal({
                    icon: "error",
                    text: "Error during upload !",
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        deleteContentFolder: async function () {

            var that = this;

            var response = await apidoxweb.deleteFolderContent(
                0
            ).then(async (res) => {

                console.log("res from deleteFolderContent", res);

                setTimeout(() => {

                    that.uploadFile();

                }, 150);


            }).catch(err => {

                that.$modal.hide('popupWorkInProgress');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;
                console.log(msg);

                that.$swal({
                    icon: "error",
                    text: "Error during execute process !",
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        step2SplitPdfBusteInDox: async function () {

            var that = this;

            var response2 = await apidoxweb.step2SplitPdfBusteInDox(
                0
            ).then(async (res2) => {

                console.log("res from step2SplitPdfBusteInDox", res2);



                setTimeout(() => {

                    router.push({
                        path: "/dash"
                    });


                }, 200);



            }).catch(err2 => {


                that.$modal.hide('popupWorkInProgress');

                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg2 = err2.response.data.Error;
                console.log(msg2);

                that.$swal({
                    icon: "error",
                    text: "Error during execute process !",
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response2);

            }

            );



        },


        step2SplitPdfCudInDox: async function () {

            var that = this;

            var response2 = await apidoxweb.step2SplitPdfCudInDox(
                0
            ).then(async (res2) => {

                console.log("res from step2SplitPdfCudInDox", res2);



                setTimeout(() => {

                    router.push({
                        path: "/dash"
                    });


                }, 200);



            }).catch(err2 => {


                that.$modal.hide('popupWorkInProgress');

                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg2 = err2.response.data.Error;
                console.log(msg2);

                that.$swal({
                    icon: "error",
                    text: "Error during execute process !",
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response2);

            }

            );



        },





        step1CopyPdfOrigInCache: async function () {


            var that = this;


            var response = await apidoxweb.step1CopyPdfOrigInCache(
                0
            ).then(async (res) => {

                console.log("res from step1CopyPdfOrigInCache", res);


                setTimeout(() => {

                    $("#divMessages").html("Split pdf file and put file in doxweb");


                }, 150);



                if (that.valueSelected == "cedolini") {


                    setTimeout(() => {

                        that.$modal.hide('popupWorkInProgress');

                        that.step2SplitPdfBusteInDox();


                    }, 1000);


                }
                else {

                    setTimeout(() => {

                        that.$modal.hide('popupWorkInProgress');

                        that.step2SplitPdfCudInDox();


                    }, 1000);



                }








            }).catch(err => {

                that.$modal.hide('popupWorkInProgress');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;
                console.log(msg);

                that.$swal({
                    icon: "error",
                    text: "Error during execute process !",
                    showConfirmButton: false,
                    timer: 3000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );














        },

        uploadFile: async function () {


            var that = this;




            var up_cedolini = true;



            var response = await apidoxweb.uploadFileNew(
                "/",
                up_cedolini,
                that.valueSelected,
                $("#input-file-attach")[0].files[0]
            ).then(async (res) => {

                console.log("res from uploadFile", res);

                if (res.data.Result == "OK") {

                    setTimeout(() => {


                        $("#divMessages").html("Copy file in cache folder");

                        that.step1CopyPdfOrigInCache();


                    }, 150);

                }
                else {

                    that.$modal.hide('popupWorkInProgress');

                    setTimeout(() => {

                        that.$swal({
                            icon: "error",
                            text: "Error during execute process",
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }, 150);


                }


            }).catch(err => {


                that.$modal.hide('popupWorkInProgress');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;
                console.log(msg);

                that.$swal({
                    icon: "error",
                    text: "Error during execute process !",
                    showConfirmButton: false,
                    timer: 3000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );














        },




        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Confirm",
                        icon: "mdi-content-save",
                        image: this.getUrlDomain("public/_lib/img/conferma-doxweb.png"),
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Confirm",
                        width: 30
                    },





                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: this.getUrlDomain("public/_lib/img/conferma-doxweb.png"),
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Conferma",
                        width: 30

                    },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length == 0) {

                errore = "File required";

            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "The accepted file formats are " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "The file size must be between " + strMin + " and " + strMax;

                        }


                    }






                }

            }


            if (errore == "") {

                console.log("ok");


                that.$modal.show('popupWorkInProgress');



                setTimeout(() => {


                    $("#divMessages").html("Upload file");

                    that.deleteContentFolder();


                }, 150);



            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }





        },








    }

})
</script>

<style>
.carica_cedolini_cud .dropify-wrapper {
    margin-left: 0px !important;
}




.w-container-carica-cedolini-cud {

    overflow-y: hidden !important;


}

.w-container-edit-user-emt .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-user-emt {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.carica_cedolini_cud .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}



.carica_cedolini_cud .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.carica_cedolini_cud .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.carica_cedolini_cud ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



.carica_cedolini_cud .cl-checkbox i {
    font-size: 13px !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
